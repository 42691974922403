import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import Nav from '../components/Nav';

import SEO from '../components/SEO';

const WorkContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const WorkContainerSafe = styled.div`
  width: 80%;
  @media (max-width: 768px) {
    width: 100%;
  }
  padding-top: 1.5rem;
`;

export default function ContactPage() {
  return (
    <>
      <SEO title="Contact" />

      <WorkContainer>
        <WorkContainerSafe>
          <h1 className="heavy">Contact</h1>
          <h2 className="light">
            I'd love to hear from you. You can find me all over the internet.
          </h2>
          <ul>
            <li>
              <h3 className="light"> Twitter: @j_m_barnes L</h3>
            </li>
          </ul>
          <h3 className="light" />
        </WorkContainerSafe>
      </WorkContainer>
    </>
  );
}
